.overlay-screen {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  background-color: #00000070;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);

  &.open {
    pointer-events: all;
    opacity: 1;
    z-index: 2;
  }
}
