.predictions-list {
  position: absolute; /* Ensure the list is positioned relative to the input field */
  top: 100%; /* Align it just below the input field */
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000 !important; /* Ensure it appears above other elements */
  max-height: 200px; /* Limit height, scroll if there are too many items */
  overflow-y: auto; /* Enable scrolling */
  padding: 0;
  margin: 0;
  list-style: none; /* Remove default bullet points */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow for better visibility */
}

/* Styles for individual list items */
.predictions-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

/* Hover state for list items */
.predictions-list li:hover {
  background-color: #f0f0f0;
}

/* Active or selected state for list items */
.predictions-list li.active {
  background-color: #e0e0e0;
}
