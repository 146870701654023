.reservation-info {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  > h1 {
    font-size: 22px;
  }

  .reservation-more-info {
    flex: 1;
    font-size: 16px;
    display: flex;
    gap: 0.25rem;
    margin-top: 0.25rem;
    > div:not(:last-child) {
      position: relative;
      &::after {
        content: "·";
        display: inline-block;
        margin-inline-start: 0.25rem;
      }
    }
  }
}
