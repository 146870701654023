.img-carousel {
    position: relative;
    z-index: 0;

    .arrow-right {
        right: 20px;
    }
    .arrow-left {
        left: 20px;
    }
    .arrow-left,
    .arrow-right {
        transition: opacity 0.3s $ease-in-out;
        opacity: 0;
        z-index: 9;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: max-content;
        height: max-content;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: 0;
        outline: 0;

        // Button bg definitions and states
        &::before {
            z-index: -1;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: auto;
            background-color: rgba(255, 255, 255, 0.9);
            cursor: pointer;
            width: 2rem;
            height: 2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 55px;
            border: 0 0 0 1px transparent, 0 0 0 4px transparent, 1px solid rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
            transition: transform 0.25s ease;
            transform-origin: 0% 0%;
        }

        &:hover::before {
            background-color: #fff;
            transform: scale(1.05) translate(-50%, -50%);
        }
        &:active::before {
            background-color: #fff;
            transform: scale(0.95) translate(-50%, -50%);
        }
        &.disabled {
            display: none;
        }
    }
    &:hover .arrow-left,
    &:hover .arrow-right {
        opacity: 1;
    }

    .dots-pagination {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        z-index: 99;
        > div {
            cursor: pointer;
            display: inline-flex;
            border-radius: 55px;
            width: 6px;
            height: 6px;
            background-color: #ffffff7e;
            &.active {
                background-color: #ffffffe7;
            }
        }
    }
}
.images-container {
    z-index: 0;
    display: flex;
    flex-wrap: nowrap;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.023) 0px 6px 8px -2px, rgba(0, 0, 0, 0.1) 0px 3px 7px -3px;
    overflow-x: scroll;
    border-radius: px-to-rem(12px);
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    margin-bottom: 1.25rem;
    position: relative;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
    ::selection {
        background-color: transparent;
    }
    .carousel-item-container {
        @include aspect-ratio(20, 19);
        min-height: 100%;
        min-width: 100%;
        scroll-snap-align: start;

        overflow: hidden;
    }
}
