.marker-container {
    position: relative;
    width: 100%;
    &:hover {
        z-index: 99;
    }
    .marker {
        cursor: pointer;
        background-color: white;
        color: $black;
        font-size: 1rem;
        font-weight: 600;
        box-shadow: 0px 3px 10px -7px rgba(0, 0, 0, 0.479);
        border-radius: 55px;
        border: none;
        margin: 0 auto;
        padding: px-to-rem(6px) px-to-rem(10px);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        transform: scale(1);
        transform-origin: 50% 50%;
        transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
            transform: scale(1.1);
            z-index: 10;
        }
    }
    &.active .marker {
        transform: scale(1.1);
        z-index: 9;
        background-color: $black;
        color: white;
    }
}
