.app-modal,
.modal-overlay {
    position: fixed;
    z-index: 99;
    opacity: 0;
    transition: 0.2s opacity;
    pointer-events: none;
    &.open {
        pointer-events: all;
        opacity: 1;
    }
}
.modal-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000008e;
}
.app-modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.app-modal {
    &.open {
        pointer-events: all;
        opacity: 1;
    }
    > .close-btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 55px;
        border: 1px solid $gray-D;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        transition: transform 0.2s;
        &:hover {
            transform: scale(1.05);
        }
    }
}
