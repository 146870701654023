@mixin padding-layout($padding-lg, $padding-md, $padding-sm) {
    padding-inline: $padding-lg;

    @media (max-width: $main-break-md) {
        padding-inline: $padding-md;
    }

    @media (max-width: $break-sm) {
        padding-inline: $padding-sm;
    }
}

//aspect ratio mixin
@mixin aspect-ratio($width: 1, $height: 1) {
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: calc($height / $width) * 100%;
    }

    > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

// Mixin for removing hover efects on iphone screen
@mixin hover-supported {
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
}

// Mixin for Avatar
@mixin avatar($size: 62px) {
    width: $size;
    height: $size;
    border-radius: 100px;
    margin: auto;
    overflow: hidden;
    vertical-align: middle;
    align-self: center;
    box-shadow: 0px 0px 0px 2px $primary;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1) box-shadow;
    &:hover {
        box-shadow: 0px 0px 0px 2px $primary-highlighted;
    }
}
