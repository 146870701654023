.skeleton-preview {
    border-radius: 1rem;

    .img-skeleton {
        @include aspect-ratio(20, 19);
        border-radius: 1rem;
        background-image: linear-gradient(-90deg, $gray-D, $gray-E, $gray-D);
        background-size: 200% 100%;
        animation: skeleton-pulse 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
    .group {
        gap: 1rem;
    }
    .meta {
        margin-top: 1rem;
        padding: 0.75rem 0;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .grow {
            min-width: 25%;
            flex: 1;
            gap: 0.5rem;
            justify-content: flex-end;
        }
        .icon-skeleton {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-image: linear-gradient(-90deg, $gray-D, $gray-E, $gray-D);
            background-size: 200% 100%;
            animation: skeleton-pulse 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        }
        .text-skeleton {
            width: 100%;
            height: 1rem;
            background-image: linear-gradient(-90deg, $gray-D, $gray-E, $gray-D);
            background-size: 200% 100%;
            animation: skeleton-pulse 1s 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
            border-radius: 3px;
            &:nth-child(2) {
                width: 50%;
            }
            &.price {
                width: 33%;
            }
        }
        .label-skeleton {
            flex: 1;
            width: 33%;
            height: 1rem;
            background-image: linear-gradient(-90deg, $gray-D, $gray-E, $gray-D);
            background-size: 200% 100%;
            animation: skeleton-pulse 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        }
    }
}

.skeleton-details {
    background-image: linear-gradient(-90deg, $gray-D, $gray-E, $gray-D);
    background-size: 200% 100%;
    animation: skeleton-pulse 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    &.title-skeleton {
        margin-bottom: 1rem;
        height: 26px;
        line-height: 1.5;
        width: 40%;
        border-radius: 0.5rem;
    }
    &.text-skeleton {
        // height: 1rem;
        min-height: 100%;
        width: 100%;
        border-radius: 0.5rem;
    }
    &.img-skeleton {
        min-height: 100%;
        min-width: 100%;
        border-radius: 1rem;
    }
    &.avatar-skeleton {
        border-radius: 1rem;
        height: 3.75rem;
        width: 3.75rem;
        border-radius: 5rem;
    }
}

@keyframes skeleton-pulse {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -200% 0;
    }
}
