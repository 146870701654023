.search-form-container {
    max-width: 850px;
    width: 100%;
    position: absolute;
    bottom: -1.25rem;
    left: 50%;
    transform: translate(-50%, -2rem) scale(0.25);
    margin: 0 auto;
    transition: all 0.25s ease-in-out;
    opacity: 0;
    pointer-events: none;
    @media (min-width: $secondary-break-sm) {
        padding: 0;
    }
    &.expanded {
        transition: all 0.25s ease-in-out;
        transform: translate(-50%, 100%) scale(1);
        opacity: 1;
        pointer-events: all;
    }
    &.blured {
        .search-form {
            background-color: #fff;
        }
    }
}

.search-form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 1px solid $gray-E;
    background-color: $gray-E;
    .module-container {
        cursor: pointer;
        border-radius: 155px;
        display: flex;
        align-items: center;

        &.location,
        &.startDate,
        &.guests {
            flex: 1;
        }
    }
}
