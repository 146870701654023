:root {
  --red: #ff385c;
  --pink: #e61e4d;
  --magenta: #e31c5f;
  --purple: #d70466;
  --dark-purple: #bd1e59;
}

// COLOR PALETTE
$black: #222;
$gray-200: #212121;
$gray-300: #313131;
$gray-400: #414141;
$gray-500: #515151;
$gray-600: #616161;
$gray-700: #717171;
$gray-B: #b0b0b0;
$gray-D: #dddddd;
$gray-E: #ebebeb;
$gray-F: #f7f7f7;

$primary: rgb(56, 168, 255);
$primary-highlighted: rgb(30, 144, 230);

$text-muted: #717171;

$brand-gradient: radial-gradient(
  circle at center,
  #ff385c 0%,
  #e61e4d 27.5%,
  #e31c5f 40%,
  #d70466 57.5%,
  #bd1e59 75%,
  #bd1e59 100%
);

// FONT
$base-font-family: "Cereal-Book", Arial, Helvetica, sans-serif;
$base-font-size: 16px;
$base-font-ratio: 1.43;
$base-text-color: rgb(34, 34, 34);

// FONT VARS
$font-family-medium: "Cereal-Medium", Arial, Helvetica, sans-serif;
$font-family-bold: "Cereal-Bold", Arial, Helvetica, sans-serif;

// FIXED SIZES
$header-height-desktop: 82px;
$header-height-desktop: 131px;
$filters-height-desktop: 84px;

// easing
$ease-in-out: cubic-bezier(0.22, 1, 0.36, 1);
