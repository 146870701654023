.app-header {
  position: relative;
  z-index: 9;
  position: sticky;
  top: 0;
  max-height: $header-height-desktop;
  header {
    background-color: white;
    z-index: 2;
    border-bottom: 1px solid $gray-F;
    .logo {
      cursor: pointer;
      color: $primary;
      padding-left: 1%;
    }
    // Header bg-color extension when expanded as ::before
    &::before {
      content: "";
      padding: 1rem 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: white;
      transition: 0.3s all ease;
      z-index: -1;
    }
    &.expanded {
      transition: 0.3s all ease;
      &::before {
        transition: 0.3s all ease;
        height: calc(200% + 2rem);
      }
    }
    // Header content wrapper
    .wrapper {
      padding: 1rem 0;
      position: sticky;
    }
  }
  .overlay-screen {
    z-index: -1;
  }
  .user-menu {
    display: flex;

    .icon {
      padding-top: 2px;
      height: min-content;
    }

    .sell-reservation-button {
      margin: 5px;
      padding: 10px;
      border-radius: 40px;
      max-width: fit-content;
      margin-left: auto;
      margin-right: auto;

      :hover {
        background-color: #f7f7f7;
      }
    }

    .icons-sign-in {
      display: flex;
      border: 1px solid #c8c7c7;
      border-radius: 35px;
      padding: 5px;
      height: min-content;
      transition: box-shadow 0.5s ease;
    }

    .icons-sign-in:hover {
      box-shadow: 2px 2px 5px rgba(36, 36, 36, 0.5);
    }
  }

  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dialog-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

    button {
      margin-top: 20px;
      padding: 8px 16px;
    }
  }
}
.mobile-header {
  padding-top: 1rem;
  font-size: 14px;
}

.popup {
  position: absolute;
  top: calc($header-height-desktop - 1vh); /* Adjust based on your layout */
  right: 2em;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 1000;
  width: 200px;
}

.popup ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.popup li {
  margin: 10px 0;
  padding: 10px;
  padding-left: 20px;
}
.popup li:hover {
  background-color: #f7f7f7;
}

.popup a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.profile {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
