.sticky-section-wrapper {
  position: relative;
  flex-wrap: wrap;
  display: flex;
  border-bottom: 1px solid $gray-E;
  padding-bottom: 3rem;
  @media (min-width: 1185px) {
    .main-details {
      padding-inline-end: 5rem;
      width: 67%;
    }
  }
  .reservation {
    min-width: 33%;
    margin-inline-start: auto;
    position: sticky;
    top: calc($header-height-desktop + 3.25rem);
    border: 1px solid rgb(221, 221, 221);
    border-radius: 12px;
    height: max-content;
    padding: 24px;
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
    margin-top: 3rem;
    z-index: 11;
  }
}

.reservation-header {
  .header-price {
    font-size: 22px;
    .suffix {
      font-size: 16px;
      font-family: $base-font-family;
    }
  }
}

.reservation-pickers {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  border: 1px solid $gray-B;
  border-radius: 0.5rem;
  margin-block: 1rem;
  .dates-wrapper {
    border-bottom: 1px solid $gray-B;
    .inputs-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      > :first-child {
        border-right: 1px solid $gray-B;
      }
    }
    &.open {
      z-index: 3;
      position: relative;
      .inputs-wrapper {
        border-radius: 0.5rem;
        border: 1px solid $gray-B;
      }
      .date-picker-wrapper {
        background: rgb(255, 255, 255);
        border-radius: 1rem;
        box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
        display: inline-block;
        padding: 1.5rem 2rem 1rem;
        position: absolute;
        top: -1rem;
        right: -1.75rem;
        width: 661px;
        z-index: -1;
        min-height: 460px;
      }
    }
  }
  .reservation-module {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    &:focus-within {
      border-radius: 0.5rem;
      box-shadow: 0px 0px 0px 2px black;
      outline: 0;
    }
    > span {
      font-size: 10px;
      text-transform: uppercase;
      user-select: none;
    }
    > input {
      font-size: 14px;
      background-color: transparent;
      width: 100%;
      border: 0;
      &:focus-within {
        border: 0;
        outline: 0;
      }
    }
  }
  > :last-child {
    grid-row: 2/-1;
    grid-column: 1/-1;
  }
  .guests-wrapper {
    position: relative;
    .guests-picker-wrapper {
      background-color: white;
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      padding: 1.5rem;
      z-index: 22;
      box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
      border-radius: 1rem;
      .search-guests {
        margin-bottom: 1.5rem;
      }
    }
  }
  .close-picker-btn {
    font-family: $font-family-medium;
    font-size: 14px;
    line-height: 1.25;
    background-color: $black;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
}

.airbnb-btn {
  --mouseX: 92;
  --mouseY: 73;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-family: $font-family-medium;
  width: 100%;
  padding: 0.75rem;
  color: white;

  position: relative;
  overflow: hidden;
  background-image: radial-gradient(
    circle,
    rgb(56, 168, 255) 0%,
    /* Light Blue */ rgb(30, 144, 230) 27.5%,
    /* Blue */ rgb(28, 150, 227) 40%,
    /* Slightly darker blue */ rgb(4, 170, 215) 57.5%,
    /* Aqua blue */ rgb(30, 189, 169) 75%,
    /* Teal */ rgb(30, 189, 169) 100% /* Teal */
  );

  background-size: 200% 200%;
  background-position: calc((100 - var(--mouseX, 0)) * 1%)
    calc((100 - var(--mouseY, 0)) * 1%);

  transition: background-position linear;
}

.reservation-total {
  padding-top: 1.5rem;
  .wont-charged-msg {
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 0.95rem;
  }
  .total-field {
    margin-bottom: 1rem;
  }
  .total-sum {
    padding-top: 1rem;
    border-top: 1px solid $gray-E;
  }
}
