.add-reservation {
  margin-bottom: 100px;
}

.create-form {
  .form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .form-container h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .input-field select {
    margin-bottom: 1rem;
  }

  .input-field label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .input-field {
    margin-bottom: 2em;
  }

  .input-field input,
  .input-field select,
  .input-field textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .input-field input[type="datetime-local"] {
    display: block;
  }

  .reservation-summary {
    height: 4em;
    width: 100%;
    resize: vertical;
    margin-bottom: 0;
  }

  .error {
    color: red;
    font-size: 0.875rem;
  }

  .create-reservation-button {
    display: block;
    width: 15em;
    float: right;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    text-align: center;
  }

  .create-reservation-button:hover {
    background-color: rgb(229, 229, 229);
  }

  .upload-files-button {
    background-color: white;
    padding: 10px 15px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
  }

  .thumbnail-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .thumbnail {
    position: relative;
    width: 100px;
    height: 100px;
  }

  .thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .remove-thumbnail {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: white;
    border: none;
    color: black;

    border: 1px solid #88888888;
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .location-search-container {
    position: relative;
  }
}
