.module {
    cursor: pointer;

    height: 66px;
    border-radius: 155px;
    width: 100%;
    // flex: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    .airbnb-btn {
        margin-inline-end: 1rem;
        border-radius: 55px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        transition: all 0.3s;
        max-width: 100%;
        min-width: max-content;
        width: 2rem;
        height: 3rem;
        padding: 0 1rem;
        font-family: $font-family-medium;
    }
    &:hover:not(.active) {
        background-color: #e0e0e0;
    }
    > div {
        width: 100%;
        padding: 0 2rem;
        display: grid;
        align-items: center;
        place-content: center;
        grid-template-columns: 1fr auto;
        grid-template-areas:
            'filterTitle resetBtn'
            'filterSelection resetBtn';
    }
    &.active {
        background-color: #fff;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    }
    // &.guests {
    //     padding: 0 0rem 0 2rem;
    // }
    .module-title {
        grid-area: filterTitle;
        font-size: 12px;
        font-family: $font-family-medium;
    }
    .module-selection {
        all: unset;
        width: 100%;
        grid-area: filterSelection;
        font-size: 14px;
        font-family: $font-family-medium;
        border: 0;
        background-color: transparent;
        // max-width: max-content;
        &::placeholder {
            font-family: $base-font-family;
            color: $gray-700;
        }
        &:focus-within {
            border: 0;
            outline: 0;
        }
    }

    .module-reset-btn {
        cursor: pointer;
        grid-area: resetBtn;
        border-radius: 155px;
        border: 0;
    }
    .module-reset-btn {
        background-color: $gray-D;
        margin-inline-start: 1rem;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        display: none;
        &.shown {
            display: inline-flex;
            opacity: 1;
            pointer-events: all;
        }
    }
}
