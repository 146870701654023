.search-regions {
    .regions-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem 1.5rem;
        margin-top: 1rem;
        > li {
            img {
                cursor: pointer;
                border-radius: 0.5rem;
                overflow: hidden;
                box-shadow: 0px 0px 0px 1px $gray-D;
                transition: 0.2s all;
                margin-bottom: 0.5rem;
                &:hover {
                    box-shadow: 0px 0px 0px 1px $gray-400;
                }
                &.active {
                    box-shadow: 0px 0px 0px 2px $gray-400;
                }
            }
            span {
                color: $gray-400;
            }
        }
    }
}
