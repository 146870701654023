.details-date-picker {
    h1 {
        font-size: 22px;
        margin-bottom: 0.5rem;
    }
    p {
        margin-bottom: 1rem;
    }
    .clear-dates-link {
        margin: 0;
        cursor: pointer;
        text-align: end;
        font-size: 14px;
        font-family: $font-family-medium;
        text-decoration: underline;
    }
    .actions {
        gap: 1rem;
    }
}
