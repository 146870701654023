.review-title {
    display: inline-flex;
    align-items: center;

    gap: 0.25rem;
    &.lg {
        font-size: 1.33rem;
        font-family: $font-family-medium;
        > svg {
            font-size: 1rem;
        }
    }
    &.sm {
        font-family: $base-font-family;
        font-size: 1rem;
        .text-skeleton {
            width: 30px;
            height: 1rem;
            border-radius: 2px;
        }
    }
}
