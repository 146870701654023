.reservation-list {
  display: grid;
  grid-auto-flow: row dense;
  gap: 40px 24px;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-auto-rows: 1fr;
  > * {
    position: relative;
  }
  .scroll-indicator {
    position: absolute;
    z-index: -2;
    pointer-events: none;
    background-color: transparent;
    width: 100%;
    height: 10px;
    top: 0;
  }
}
