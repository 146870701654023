.reservation-amenities {
  h1 {
    font-size: 22px;
    margin-bottom: 1rem;
  }
  .amenities-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    li {
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      img {
        width: 1.5rem;
      }
    }
  }
}
