// Font helpers
.font-bold {
    font-family: $font-family-bold;
    font-weight: 400;
}
.font-medium {
    font-family: $font-family-medium;
    font-weight: 400;
}
.font-base {
    font-family: $base-font-family;
    font-weight: 400;
}

.text-muted {
    color: $text-muted;
}

// Scrollbar
.disable-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0px;
        height: 0;
    }
}

// List
.clean-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.inline-clamp {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    line-height: inherit;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.line-clamp-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}
.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

// Inline space
.space-inline-s {
    & > *:not(:last-child) {
        margin-inline-end: 1em;
    }
}

.space-inline-m {
    & > *:not(:last-child) {
        margin-inline-end: 2em;
    }
}

.space-inline-l {
    & > *:not(:last-child) {
        margin-inline-end: 3em;
    }
}

// Aspect ratios

.ratio-16-9 {
    @include aspect-ratio(100, 56);
}

.ratio-square {
    @include aspect-ratio(100, 100);
}

.ratio-card {
    @include aspect-ratio(100, 80);
}

.ratio-poster {
    @include aspect-ratio(100, 150);
}

.flex {
    display: flex;

    &.column {
        flex-direction: column;
    }

    &.flex-center {
        align-items: center;
        justify-content: center;
    }

    &.justify-between {
        justify-content: space-between;
    }

    &.justify-center {
        justify-content: center;
    }

    &.justify-start {
        justify-content: flex-start;
    }

    &.justify-end {
        justify-content: flex-end;
    }

    &.align-center {
        align-items: center;
    }
}
.hidden {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}
.avatar {
    width: 3.75rem;
    height: 3.75rem;
    object-fit: cover;
    border-radius: 55px;
    &.sm {
        width: 2.75rem;
        height: 2.75rem;
    }
}
