@font-face {
    font-family: 'Cereal-Book';
    src: url('../../fonts/Cereal/Cereal-Book.ttf') format('truetype');
}
@font-face {
    font-family: 'Cereal-Medium';
    src: url('../../fonts/Cereal/Cereal-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Cereal-Bold';
    src: url('../../fonts/Cereal/Cereal-Bold.ttf') format('truetype');
}

body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-font-ratio;
    color: $base-text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Cereal-Medium';
    font-weight: 400;
}
h1 {
    font-size: 26px;
}
