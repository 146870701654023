.main-content {
  display: flex;
  flex-direction: column;
}
.home {
  grid-column: 1/-1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  .filters {
    position: sticky;
    top: $header-height-desktop;
    background-color: #fff;
    z-index: 1;
    max-height: $filters-height-desktop;
  }
  .reservation-index {
    flex: 1;
    height: 100%;
    display: flex;

    .index-map {
      align-self: flex-start;
      position: sticky;
      z-index: 0;
      top: $header-height-desktop + $filters-height-desktop;
      width: 50%;
      height: calc(100vh - $header-height-desktop - $filters-height-desktop);
      padding: 0;
    }
    .reservation-list {
      flex: 1;
      height: 100%;
      padding-block: 1.5rem 6rem;
    }
  }
  .view-toggle-btn {
    cursor: pointer;
    position: fixed;
    bottom: 6rem;
    left: 50%;
    transform: translateX(-50%);
    border: 0;
    background-color: $base-text-color;
    color: $gray-F;
    font-family: $font-family-medium;
    font-weight: normal;
    letter-spacing: -0.5px;
    padding: 0.75rem 1.5rem;
    border-radius: 5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.home-footer {
  font-size: 14px;
  border-top: 1px solid $gray-E;
  bottom: 0;
  background: white;
  width: 100%;
  padding-block: 1rem;
}
