.rates-list {
    .rate-list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.75rem;
        .rate-name {
            text-transform: capitalize;
        }
        .rate-value-container {
            display: flex;
            align-items: center;
            width: 33%;
            gap: 0.75rem;
            font-size: 12px;
            font-family: $font-family-medium;
        }
    }
}
