.search-module-extension {
    position: absolute;
    width: 100%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -0.5rem;
    background-color: #fff;
    padding: 2.5rem;
    transform: translate(-50%, 100%);
    border-radius: 2rem;
    z-index: 9;
    font-size: 15px;
    &.pos-start,
    &.pos-end {
        transform: translate(0, 100%);
        max-width: 50%;
    }
    &.pos-start {
        left: 0;
    }
    &.pos-end {
        right: 0;
        left: unset;
    }
}
