.image-gallery {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem;
    border-radius: 0.5rem;
    overflow: hidden;
    max-height: calc(60vh - 2rem);

    > * {
        width: 100%;
    }

    > *:first-child {
        grid-row: 1/-1;
    }
}

.image-gallery-item-container {
    position: relative;
    min-height: 0;
    padding-top: calc(90% - 0.5rem);

    &.img-skeleton {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(-90deg, $gray-D, $gray-E, $gray-D);
            background-size: 200% 100%;
            animation: skeleton-pulse 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        }
    }
}

.image-gallery-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
