/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
    box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
    margin: 0;
}
label,
input,
button {
    display: inline-flex;
    align-items: center;
}

/*
  3. Allow percentage-based heights in the application
*/

textarea {
    resize: none;
}

svg {
    vertical-align: middle;
}

img,
picture,
video,
svg,
canvas {
    display: block;
    max-width: 100%;
}

svg {
    vertical-align: middle;
    box-sizing: border-box;
    display: revert;
}

a {
    text-decoration: none;
    color: inherit;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select,
a {
    font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.main-content {
    z-index: 0;
    position: relative;
}
